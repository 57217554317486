<template>
    <div class="dialog-box">
        <el-dialog
                :title="dialogTitle"
                :visible.sync="dialogVisible"
                width="30%"
                :before-close="handleClose">
            <div class="enter-box">
                <div class="box-left">
                    标题:
                </div>
                <div class="box-right">
                    <el-input v-model="addTitle"></el-input>
                </div>
            </div>
            <span slot="footer" class="dialog-footer">
    <el-button @click="handleClose">取 消</el-button>
    <el-button type="primary" @click="emitData">确 定</el-button>
  </span>
        </el-dialog>
    </div>
</template>

<script>
    export default {
        props: ['dialogVisible', 'title', 'dialogLevel', 'editTitle'],
        data() {
            return {
                dialogTitle: '',
                addTitle: "",
            }
        },
        mounted() {
            let child = this.dialogLevel === 1 ? '' : '子';
            let isEdit = this.editTitle === '' ? '添加' : '编辑';
            if (this.editTitle !== '') {
                this.addTitle = this.editTitle;
            }
            this.dialogTitle = this.title + '-' + isEdit + child + '标题';
        },
        methods: {
            handleClose() {
                this.$emit('closeDialog', false)
            },
            emitData() {
                if (this.addTitle === '') {
                    this.$message.warning('标题不能为空');
                    return
                }
                this.$emit("saveData", this.addTitle)
            }
        }
    }
</script>

<style lang="scss" scoped>
    ::v-deep .el-dialog__header::after {
        content: '';
        width: 100%;
        height: 10px;
        display: block;
        margin: 0 auto;
        border-bottom: 1px solid #EEEEEE;
    }

    .enter-box {
        display: flex;
        padding: 0 40px;
        align-items: center;
        height: 150px;

        .box-left {

        }

        .box-right {
            margin-left: 13px;
            width: 400px;
        }
    }
</style>